import ToastMessage from "@/components/toast";
import { createAccount } from "@/server/account.service";
import {
  Account,
  AccountCreateDto,
  AccountMessage,
  SMTPProvider,
} from "@/types";
import { Input, Button } from "@nextui-org/react";
import { Switch } from "@nextui-org/switch";
import { FC, useState, useEffect } from "react";
import { ReactComponent as EyeIcon } from "@/assets/icons/eye-icon.svg";
import { ReactComponent as EyeClosedIcon } from "@/assets/icons/eye-closed-icon.svg";

const initialData: AccountCreateDto = {
  email: "",
  smtpEncryptionMethod: "",
  smtpPassword: "",
  smtpServerHost: "",
  smtpServerPort: "",
  type: "SMTP",
};

interface Props {
  provider: SMTPProvider | null;
  account: Account | null;
  onBack: () => void;
  onAddSMTP: () => void;
}

export const SMTPForm: FC<Props> = ({
  provider,
  account,
  onBack,
  onAddSMTP,
}) => {
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [data, setData] = useState<AccountCreateDto>(initialData);

  const [errorMessage, setErrorMessage] = useState<AccountCreateDto>({
    email: "",
    smtpEncryptionMethod: "",
    smtpPassword: "",
    smtpServerHost: "",
    smtpServerPort: "",
    type: "",
  });

  useEffect(() => {
    if (account) {
      setData({
        ...data,
        smtpServerHost: account.smtpServerHost,
        email: account.email,
      });
    } else {
      setData(initialData);
    }
    //eslint-disable-next-line
  }, [account]);

  useEffect(() => {
    if (provider) {
      setData({
        ...data,
        smtpServerHost: provider.host,
        smtpServerPort:
          data.smtpEncryptionMethod === "STARTTLS"
            ? provider.portWithSSL
            : provider.port,
      });
      setErrorMessage({
        ...errorMessage,
        smtpServerHost: "",
        smtpServerPort: "",
      });
    }
    //eslint-disable-next-line
  }, [provider]);

  const handleChange = (e: any) => {
    if (e.target.name === "smtpEncryptionMethod") {
      setData({
        ...data,
        smtpEncryptionMethod: e.target.checked ? "STARTTLS" : "NONE",
        smtpServerPort: provider
          ? e.target.checked
            ? provider.portWithSSL
            : provider.port
          : "",
      });
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }
  };

  const onAddService = async (e: any) => {
    e.preventDefault();

    let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
    let message = errorMessage;

    if (!regex.test(data.email) || !data.email)
      message = { ...message, email: "Please enter a valid email " };

    if (!data.smtpPassword)
      message = { ...message, smtpPassword: "Please enter password " };

    if (!data.smtpServerHost)
      message = {
        ...message,
        smtpServerHost: "Please enter SMTP Server Host ",
      };

    if (!data.smtpServerPort)
      message = {
        ...message,
        smtpServerPort: "Please enter SMTP Server Port ",
      };

    setErrorMessage(message);

    if (Object.values(message).join("") !== "") {
      return;
    }

    setIsSubmitting(true);
    try {
      const resp: AccountMessage = await createAccount(data);
      if (resp.account) {
        onAddSMTP();
        if (account) ToastMessage("success", "Success verifying account");
        else ToastMessage("success", "Success creating an account");
      } else ToastMessage("error", resp.message);
    } catch (error) {
      ToastMessage("error", "Error while creating account");
    }
    setIsSubmitting(false);
  };

  const togglePasswordVisibility = () => setPasswordVisibility((prev) => !prev);

  return (
    <form
      className="flex flex-col gap-2 justify-between w-[350px] shrink-0"
      onSubmit={onAddService}
    >
      <div className="flex flex-col gap-2">
        <Input
          autoComplete="off"
          labelPlacement="outside"
          variant="bordered"
          className="relative mb-4"
          id="email"
          name="email"
          placeholder="you@example.com"
          label="Email"
          isInvalid={errorMessage["email"] !== ""}
          errorMessage={errorMessage["email"]}
          onClick={() => setErrorMessage({ ...errorMessage, email: "" })}
          value={data.email}
          onChange={handleChange}
          classNames={{
            errorMessage: "absolute top-0 left-0",
          }}
        />

        <Input
          type={passwordVisibility ? "text" : "password"}
          autoComplete="off"
          labelPlacement="outside"
          variant="bordered"
          className="relative mb-4"
          id="smtpPassword"
          name="smtpPassword"
          placeholder="Password"
          label="Password"
          isInvalid={errorMessage["smtpPassword"] !== ""}
          errorMessage={errorMessage["smtpPassword"]}
          onClick={() => setErrorMessage({ ...errorMessage, smtpPassword: "" })}
          value={data.smtpPassword}
          onChange={handleChange}
          classNames={{
            errorMessage: "absolute top-0 left-0",
          }}
          endContent={
            <Button
              isIconOnly
              color="primary"
              variant="light"
              onPress={togglePasswordVisibility}
              size="sm"
              className="text-gray-600 data-[hover=true]:bg-transparent data-[hover=true]:bg-transparent"
            >
              {passwordVisibility ? (
                <EyeClosedIcon width={20} />
              ) : (
                <EyeIcon width={20} />
              )}
            </Button>
          }
        />

        <Input
          autoComplete="off"
          labelPlacement="outside"
          variant="bordered"
          className="relative mb-4"
          id="smtpServerHost"
          name="smtpServerHost"
          placeholder="Server host"
          label="Server Host"
          isInvalid={errorMessage["smtpServerHost"] !== ""}
          errorMessage={errorMessage["smtpServerHost"]}
          onClick={() =>
            setErrorMessage({ ...errorMessage, smtpServerHost: "" })
          }
          value={data.smtpServerHost}
          onChange={handleChange}
          classNames={{
            errorMessage: "absolute top-0 left-0",
          }}
        />

        <Input
          autoComplete="off"
          labelPlacement="outside"
          variant="bordered"
          className="relative mb-4"
          id="smtpServerPort"
          name="smtpServerPort"
          placeholder="Server port"
          label="Port"
          isInvalid={errorMessage["smtpServerPort"] !== ""}
          errorMessage={errorMessage["smtpServerPort"]}
          onClick={() =>
            setErrorMessage({ ...errorMessage, smtpServerPort: "" })
          }
          value={data.smtpServerPort}
          onChange={handleChange}
          classNames={{
            errorMessage: "absolute top-0 left-0",
          }}
        />

        <Switch
          size="sm"
          className="mb-3"
          name="smtpEncryptionMethod"
          onChange={handleChange}
        >
          Use SSL
        </Switch>
      </div>
      <div className="flex gap-2">
        <Button
          className="w-1/3"
          color="primary"
          variant="light"
          onPress={onBack}
        >
          Go back
        </Button>
        <Button
          fullWidth
          type="submit"
          color="primary"
          isLoading={isSubmitting}
        >
          Add new service
        </Button>
      </div>
    </form>
  );
};
