import { PercentageStatus } from "@/components/percentage-status";
import { DataTable } from "@/components/table";
import { getDispatchLeaderBoard } from "@/redux/async/getAnalyticsData";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { TUserOrganisationMember } from "@/types";
import { toClockTimeBySeconds } from "@/utils/toClockTime";
import { Avatar } from "@nextui-org/react";
import { useCallback, useEffect } from "react";

type Column = {
  key: string;
  label: string;
  align?: "start" | "center" | "end";
  allowsSorting?: boolean;
};

const columns: Column[] = [
  { key: "email", label: "Email" },
  { key: "queries", label: "Queries" },
  {
    key: "avgPostToContactTime",
    label: "Avg post-to-contact Time",
  },
  { key: "avgRate", label: "Avg rate" },
  { key: "avgProductiveTime", label: "Productivity time" },
];

type CellData = {
  value: string;
  isUp: boolean;
  percentage: string;
  pastValue: string;
};

const getDataCellUI = (data: CellData) => {
  const { value, isUp, percentage, pastValue } = data;

  const isPercentageNull = !+(percentage || 0);

  return (
    <span className="flex items-baseline gap-y-0 gap-x-1 text-left flex-wrap">
      <div className="flex items-baseline gap-2">
        <p className="flex-shrink-0 text-black text-base">{value}</p>
        <PercentageStatus isUp={isUp} value={percentage} />
      </div>
      {!isPercentageNull && (
        <span className="text-xs text-gray-500">
          vs {pastValue} last period
        </span>
      )}
    </span>
  );
};

const getCombinedDataCellUI = (emailData: CellData, phoneData: CellData) => {
  const emailUI = getDataCellUI(emailData);
  const phoneUI = getDataCellUI(phoneData);

  return (
    <div className="flex flex-col gap-2">
      <div>
        <p className="text-gray-500">Email</p>
        {emailUI}
      </div>
      <div>
        <p className="text-gray-500">Phone</p>
        {phoneUI}
      </div>
    </div>
  );
};

export const DispatchersTable = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(
    (state) => state.analyticsReducer.dispatchLeadeBoardLoading
  );
  const period = useAppSelector(
    (state) => state.analyticsReducer.managerQueriesPeriod
  );
  const selectedUsers = useAppSelector(
    (state) => state.analyticsReducer.selectedUsers
  );
  const dispatchLeaderBoardData = useAppSelector(
    (state) => state.analyticsReducer.dispatchLeaderBoardData
  );
  const membersList: TUserOrganisationMember[] = useAppSelector(
    (state) => state.myTeamReducer.members
  );

  useEffect(() => {
    if (selectedUsers.length === 0) return;
    dispatch(
      getDispatchLeaderBoard({ period: period, userIds: selectedUsers })
    );
  }, [dispatch, period, selectedUsers]);

  const renderCell = useCallback(
    (row: any, columnKey: React.Key) => {
      switch (columnKey) {
        case "email":
          const member = membersList.find(
            (member) => member.userId === +row.userId
          )?.email;
          return (
            <span className="flex items-center gap-1">
              <Avatar
                size="sm"
                classNames={{
                  base: "bg-brand-700/10",
                  name: "text-brand-700 font-medium",
                }}
                name={member?.[0].toUpperCase()}
              />
              {member}
            </span>
          );
        case "queries":
          return getCombinedDataCellUI(
            {
              value: row.emailCount,
              isUp: row.emailUp,
              percentage: row.emailPercentage,
              pastValue: row.prevEmailCount,
            },
            {
              value: row.phoneCount,
              isUp: row.phoneUp,
              percentage: row.phonePercentage,
              pastValue: row.prevPhoneCount,
            }
          );

        case "avgPostToContactTime":
          return getCombinedDataCellUI(
            {
              value: toClockTimeBySeconds(+row.avgPostToContactTimeEmail),
              isUp: row.avgPostToContactTimeEmailUp,
              percentage: row.avgPostToContactTimePercentageEmail,
              pastValue: toClockTimeBySeconds(
                row.prevAvgPostToContactTimeEmail
              ),
            },
            {
              value: toClockTimeBySeconds(+row.avgPostToContactTimePhone),
              isUp: row.avgPostToContactTimePhoneUp,
              percentage: row.avgPostToContactTimePercentagePhone,
              pastValue: toClockTimeBySeconds(
                row.prevAvgPostToContactTimePhone
              ),
            }
          );
        case "avgRate":
          return getCombinedDataCellUI(
            {
              value: `$ ${row.avgRateEmail.toFixed(2)}`,
              isUp: row.avgRateEmailUp,
              percentage: row.avgRateEmailPercentage,
              pastValue: `$ ${row.prevAvgRateEmail.toFixed(2)}`,
            },
            {
              value: `$ ${row.avgRatePhone.toFixed(2)}`,
              isUp: row.avgRatePhoneUp,
              percentage: row.avgRatePhonePercentage,
              pastValue: `$ ${row.prevAvgRatePhone.toFixed(2)}`,
            }
          );

        case "avgProductiveTime":
          return getDataCellUI({
            value: toClockTimeBySeconds(+row.avgProductiveTime),
            isUp: row.avgProductiveTimeUp,
            percentage: row.avgProductiveTimePercentage,
            pastValue: toClockTimeBySeconds(+row.prevAvgProductiveTime),
          });
        default:
          return row.userId;
      }
    },
    [membersList]
  );

  return (
    <DataTable
      rows={dispatchLeaderBoardData}
      columns={columns}
      maxHeight="calc(100vh_-_741px)"
      renderCell={renderCell}
      isLoading={loading}
    />
  );
};
