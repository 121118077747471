import {
  TAnalyticsData,
  TAnalyticsDispatchLeaderBoard,
  TAnalyticsLoadboardTimeGraph,
  TAnalyticsPerformanceGraph,
  TAnalyticsPeriod,
  TAnalyticsQueriesFilters,
  TAnalyticsQueryCriteria,
  TAnalyticsQueryCriteriaShort,
  TAnalyticsQueryResponse,
} from "@/types";
import { callAnalyticApi } from "./api.service";
import {
  getCubeDispatchLoadboard,
  getCubeTimeOnLoadBoardWithInquiriesAnalytics,
  getCubeUserAVGPostToContactTimeAnalyticsData,
  getCubeUserAVGRateAnalyticsData,
  getCubeUserEmailAnalyticsData,
  getCubeUserPhoneAnalyticsData,
  getCubeUserProductiveTimeAnalyticsData,
} from "./cube.service";

// User

export async function getUserPhoneAnalyticsData(
  period: TAnalyticsPeriod,
  userIds: string[]
): Promise<TAnalyticsData> {
  /*const resp = await callAnalyticApi({
    url: `/dashboard/phones`,
    method: "get",
    params: {
      period,
    },
  });

  return resp.data;*/
  return getCubeUserPhoneAnalyticsData(period, userIds);
}

export async function getUserEmailAnalyticsData(
  period: TAnalyticsPeriod,
  userIds: string[]
): Promise<TAnalyticsData> {
  /* const resp = await callAnalyticApi({
    url: `/dashboard/emails`,
    method: "get",
    params: {
      period,
    },
  });

  return resp.data;*/
  return getCubeUserEmailAnalyticsData(period, userIds);
}

export async function getUserAVGPostToContactTimeAnalyticsData(
  period: TAnalyticsPeriod,
  userIds: string[]
): Promise<TAnalyticsData> {
  /*const resp = await callAnalyticApi({
    url: `/dashboard/avgPostToContactTime`,
    method: "get",
    params: {
      period,
    },
  });

  return resp.data;*/
  return getCubeUserAVGPostToContactTimeAnalyticsData(period, userIds);
}

export async function getUserAVGProductiveTimeAnalyticsData(
  period: TAnalyticsPeriod,
  userIds: string[]
): Promise<TAnalyticsData> {
  /*const resp = await callAnalyticApi({
    url: `/dashboard/avgProductiveTime`,
    method: "get",
    params: {
      period,
    },
  });

  return resp.data;*/
  return getCubeUserProductiveTimeAnalyticsData(period, userIds);
}

export async function getUserAVGRateAnalyticsData(
  period: TAnalyticsPeriod,
  userIds: string[]
): Promise<TAnalyticsData> {
  /*const resp = await callAnalyticApi({
    url: `/dashboard/avgRate`,
    method: "get",
    params: {
      period,
    },
  });

  return resp.data;*/
  return getCubeUserAVGRateAnalyticsData(period, userIds);
}

export async function getAllQueriesFilters(): Promise<TAnalyticsQueriesFilters> {
  const resp = await callAnalyticApi({
    url: `/queries`,
    method: "get",
  });

  return resp.data.data;
}

export async function getAllQueries(
  criteria: TAnalyticsQueryCriteria
): Promise<TAnalyticsQueryResponse> {
  const resp = await callAnalyticApi({
    url: `/queries/all`,
    method: "post",
    data: criteria,
  });

  return resp.data;
}

export async function getUserLoadboardTimeGraph(
  userIds: string[]
): Promise<TAnalyticsLoadboardTimeGraph[]> {
  /*const resp = await callAnalyticApi({
    url: `/load-board/time`,
    method: "get",
  });

  return resp.data;*/
  return await getCubeTimeOnLoadBoardWithInquiriesAnalytics("week", userIds);
}

export async function getPerformanceGraph(
  period: TAnalyticsPeriod
): Promise<TAnalyticsPerformanceGraph> {
  const resp = await callAnalyticApi({
    url: `/load-board/performance`,
    method: "get",
    params: {
      period,
    },
  });

  return resp.data;
}

// Manager

export async function getManagerPhoneAnalyticsData(
  period: TAnalyticsPeriod,
  selectedUser: number
): Promise<TAnalyticsData> {
  const resp = await callAnalyticApi({
    url: `/manager/queries/phones`,
    method: "post",
    params: {
      period,
      user_id: selectedUser,
    },
  });

  return resp.data;
}

export async function getManagerEmailAnalyticsData(
  period: TAnalyticsPeriod,
  selectedUser: number
): Promise<TAnalyticsData> {
  const resp = await callAnalyticApi({
    url: `/manager/queries/emails`,
    method: "post",
    params: {
      period,
      user_id: selectedUser,
    },
  });

  return resp.data;
}

export async function getManagerAVGPostToContactTimeAnalyticsData(
  period: TAnalyticsPeriod,
  selectedUser: number
): Promise<TAnalyticsData> {
  const resp = await callAnalyticApi({
    url: `/manager/queries/avgPostToContactTime`,
    method: "post",
    params: {
      period,
      user_id: selectedUser,
    },
  });

  return resp.data;
}

export async function getManagerAVGProductiveTimeAnalyticsData(
  period: TAnalyticsPeriod,
  selectedUser: number
): Promise<TAnalyticsData> {
  const resp = await callAnalyticApi({
    url: `/manager/queries/avgProductiveTime`,
    method: "post",
    params: {
      period,
      user_id: selectedUser,
    },
  });

  return resp.data;
}

export async function getManagerAVGRateAnalyticsData(
  period: TAnalyticsPeriod,
  selectedUser: number
): Promise<TAnalyticsData> {
  const resp = await callAnalyticApi({
    url: `/manager/queries/avgRate`,
    method: "post",
    params: {
      period,
      user_id: selectedUser,
    },
  });

  return resp.data;
}

export async function getManagerLoadboardTimeGraph(
  selectedUser: number
): Promise<TAnalyticsLoadboardTimeGraph[]> {
  const resp = await callAnalyticApi({
    url: `/manager/queries/load-board`,
    method: "post",
    params: {
      user_id: selectedUser,
    },
  });

  return resp.data;
}

export async function getAllManagerQueries(
  criteria: TAnalyticsQueryCriteriaShort
): Promise<TAnalyticsQueryResponse> {
  const resp = await callAnalyticApi({
    url: `/manager/queries/all`,
    method: "post",
    data: criteria,
  });

  return resp.data;
}

export async function getDisPatchLeaderBoard(
  period: TAnalyticsPeriod,
  userIds: string[]
): Promise<TAnalyticsDispatchLeaderBoard[]> {
  return await getCubeDispatchLoadboard(period, userIds);
}
